import IndianChallengerElite1 from '../Elite Motorcycles Images/indian-challenger-elite-item-1.jpg';
import IndianChallengerElite2 from '../Elite Motorcycles Images/indian-challenger-elite-item-2.jpg';
import IndianChallengerElite3 from '../Elite Motorcycles Images/indian-challenger-elite-item-3.jpg';
import IndianChallengerElite4 from '../Elite Motorcycles Images/indian-challenger-elite-item-4.jpg';
import IndianChallengerElite5 from '../Elite Motorcycles Images/indian-challenger-elite-item-5.jpg';

import IndianRoadmasterElite1 from '../Elite Motorcycles Images/indian-roadmaster-elite-item-1.jpg';
import IndianRoadmasterElite2 from '../Elite Motorcycles Images/indian-roadmaster-elite-item-2.jpg';
import IndianRoadmasterElite3 from '../Elite Motorcycles Images/indian-roadmaster-elite-item-3.jpg';
import IndianRoadmasterElite4 from '../Elite Motorcycles Images/indian-roadmaster-elite-item-4.jpg';
import IndianRoadmasterElite5 from '../Elite Motorcycles Images/indian-roadmaster-elite-item-5.jpg';
import IndianRoadmasterElite6 from '../Elite Motorcycles Images/indian-roadmaster-elite-item-6.jpg';

import Roadmaster2025Elite1 from '../Elite Motorcycles Images/2025-roadmasterelite-item-1.jpg';
import Roadmaster2025Elite2 from '../Elite Motorcycles Images/2025-roadmasterelite-item-2.jpg';
import Roadmaster2025Elite3 from '../Elite Motorcycles Images/2025-roadmasterelite-item-3.jpg';
import Roadmaster2025Elite4 from '../Elite Motorcycles Images/2025-roadmasterelite-item-4.jpg';
import Roadmaster2025Elite5 from '../Elite Motorcycles Images/2025-roadmasterelite-item-5.jpg';
import Roadmaster2025Elite6 from '../Elite Motorcycles Images/2025-roadmasterelite-item-6.jpg';


const IndianChallengerEliteFeaturedItems = [
  {
    id: 1,
    mainTitle: ``,
    mainDescription: ``,
    members: [
      {
        id: 1.1,
        title: `НОВ ЕЛИТЕН МОДЕЛ`,
        description: `Прилагодено инспирираниот стил ги врти главите, додека водечките перформанси во класата обезбедуваат неспоредлива моќ и способност за возбудливо возење.`,
        image: IndianChallengerElite1,
      },
      {
        id: 1.2,
        title: `ДАЛЕКУ ПРЕТПОЗНАТЛИВ`,
        description: `Елитните модели се познати по нивните дизајни инспирирани по нарачка, шеми за бои со ограничено издание, значки, завршетоци на моторот и многу повеќе.`,
        image: IndianChallengerElite2,
      },
      {
        id: 1.3,
        title: `ЕДНОСТАВНО НАЈДОБАР`,
        description: `Овие мотоцикли доаѓаат целосно натоварени од линијата со врвни погодности од браникот до браникот. Секој модел на Elite е опремен со Select подни даски, затемнети шофершајбни, врвно осветлување и Powerband Audio за да ви овозможи почеток во изградбата на вашиот мотоцикл од соништата.`,
        image: IndianChallengerElite3,
      },
    ],
  },
  {
    id: 2,
    mainTitle: ``,
    mainDescription: ``,
    members: [
      {
        id: 2.1,
        title: `ЕКСКЛУЗИВНОСТ`,
        description: `Прилагодените мотоцикли не се наменети за усогласеност - тие се наменети за пионерите кои сакаат да одат по својот пат. За да го прослават овој дух, само 300 од секој модел се достапни низ целиот свет.`,
        image: IndianChallengerElite4,
      },
      {
        id: 2.1,
        title: `ТЕХНОЛОГИЈА ЗА ПОДОБРУВАЊЕ НА ВОЗЕЊЕТО`,
        description: `Целата технологија и аудио што ги сакате се стандардни. Прекрасен звук од 400 вати, 7-инчен екран на допир напојуван со RIDE COMMAND, прилагодливо LED осветлување и многу повеќе.`,
        image: IndianChallengerElite5,
      }
    ]
  },
];

export { IndianChallengerEliteFeaturedItems };

const IndianRoadmasterEliteFeaturedItems = [
    {
        id: 1,
        mainTitle: ``,
        mainDescription: ``,
        members: [
          {
            id: 1.1,
            title: `ИНСПИРИРАН СТИЛ`,
            description: `Indian Motorcycle Red Candy, Dark Indian Motorcycle Red Candy и Black Candy обезбедуваат врвна шема на бои што црпи инспирација од минатото и денешните сопствени мотоцикли. Комплетен со рачно обоени Шампионатски златни ленти и завршени од две од најдобрите продавници за сопствени бои во индустријата, Gunslinger Custom Paint во Колорадо и Custom Painted Vehicles од Висконсин, на секој мотоцикл му требаат повеќе од 24 часа за да се заврши.`,
            image: IndianRoadmasterElite1,
          },
          {
            id: 1.2,
            title: `ВСАДЕН ВО ИСТОРИЈАТА`,
            description: `Во 1904 година Indian Motorcycle за првпат дебитираше со иконската шема на боја на индискиот мотоцикл на брендот. Денес, ние сме возбудени да ја почитуваме нејзината иконска боја со целосно нова шема на боење со три-тонски црвени бои со врвна завршница. Во чест на оваа годишнина, централната конзола е комплетирана со силуета на Indian Camelback од 1904 година - првиот модел на индискиот мотоцикл кој дебитираше со иконската боја на Indian Motorcycle.`,
            image: IndianRoadmasterElite2,
          },
          {
            id: 1.3,
            title: `THUNDERSTROKE 116`,
            description: `Нашиот наградуван Thunderstroke 116 V-Twin има 171 Nm вртежен момент и моќ во секоја брзина.`,
            image: IndianRoadmasterElite3,
          },
        ],
      },
      {
        id: 2,
        mainTitle: ``,
        mainDescription: ``,
        members: [
          {
            id: 2.1,
            title: `ПОСВЕТЛО, ПОГОЛЕМО ОСВЕТЛУВАЊЕ`,
            description: `Приспособливиот LED фар на Pathfinder обезбедува подобрена видливост и попривлечен стил - дури и ноќе.`,
            image: IndianRoadmasterElite4,
          },
          {
            id: 2.1,
            title: `КАРАКТЕРИСТИКИ`,
            description: `Воведен во есента 2023 година, PowerBand Audio на Indian Motorcycle со засилување на басот испорачува 50% погласен звук преку 12 звучници лоцирани во предниот дел, торбите и багажникот за турнеи со уште повеќе бас во споредба со другите звучни системи.`,
            image: IndianRoadmasterElite5,
          },
          {
            id: 2.3,
            title: `СКЛАДИРАЊЕ СО ДАЛЕЧИНСКИ ЗАКЛУЧУВАЊЕ`,
            description: `Целиот товар е заштитен во пространите, атмосферски вреќи и багажникот. Едноставно притиснете едно копче на конзолата или на клучот и вашиот товар безбедно се складира.`,
            image: IndianRoadmasterElite6,
          },
        ],
      },
]

export { IndianRoadmasterEliteFeaturedItems };


const RoadmasterElite2025FeaturedItems = [
  {
      id: 1,
      mainTitle: ``,
      mainDescription: ``,
      members: [
        {
          id: 1.1,
          title: `ЕКСКЛУЗИВНО ЕЛИТЕН`,
          description: `Roadmaster Elite редефинира што да очекуваме во безвременска, но целосно модерна американска машина за турнеи со класичен и софистициран стил, рачно изработени строго прилагодени и финиширања и врвни погодности за турнеи. И со само 300 достапни ширум светот, тој е направен да биде врвен по стил и ексклузивност.`,
          image: Roadmaster2025Elite1,
        },
        {
          id: 1.2,
          title: `АМЕРИКАНСКИ СТИЛ`,
          description: `Инспириран од оригиналното Springfield Blue од раните 1900-ти, новиот Roadmaster Elite носи шема на бои со потпис во три тонови, со врвни завршетоци насекаде. Индискиот мотоцикл Springfield Blue Candy with Black Candy Fade over Silver се надополнети со Champion Gold Pinstriping кои црпат инспирација од минатото и сегашноста. Впечатливото пакување на бои е комплетирано со препознатлива Elite графика и рачно насликани златни ленти.`,
          image: Roadmaster2025Elite2,
        },
        {
          id: 1.3,
          title: `ВСАДЕН ВО ИСТОРИЈАТА`,
          description: `Помеѓу 1914-1918 година, основачот на индиската компанија за мотоцикли Џорџ Хенди ги прошири производствените капацитети на Indian Motorcycle во Спрингфилд, создавајќи го најмодерниот производствен капацитет за мотоцикли во светот. Вештите мајстори рачно го составуваа секој мотоцикл со макотрпно внимание на деталите. Покрај познатата индиска црвена боја на моторцикл, која дебитираше во 1904 година, индискиот мотоцикл Springfield Blue беше другата икона боја на брендот од оваа ера. Денес, возбудени сме што ќе го почестиме овој луксуз со новиот Roadmaster Elite за 2025 година.`,
          image: Roadmaster2025Elite3,
        },
      ],
    },
    {
      id: 2,
      mainTitle: ``,
      mainDescription: ``,
      members: [
        {
          id: 2.1,
          title: `МОЌ НА THUNDERSTROKE`,
          description: `Во срцето на врвната американска машина за турнеи е легендарниот Thunderstroke 116 V-Twin со воздушно ладење. Со вртежен момент од 126 ft-lbs, Thunderstroke 116 обезбедува исклучителна моќност при ниски вртежи во минута и непречено возење при секоја брзина со одзивноста и моќта што ја сакаат возачите на турнеја. Три различни режими на возење - Tour, Standard или Sport - и деактивирањето на задниот цилиндар дополнително го подобруваат вашето искуство во возењето обезбедувајќи модерни перформанси и функции без да се загрози безвременскиот стил.`,
          image: Roadmaster2025Elite4,
        },
        {
          id: 2.1,
          title: `ПОСВЕТЛО, ПОГОЛЕМО ОСВЕТЛУВАЊЕ`,
          description: `Прилагодливите LED светла на Pathfinder во комбинација со помошните LED светла за седло на Pathfinder обезбедуваат подобрена видливост и попривлечен стил - дури и ноќе.`,
          image: Roadmaster2025Elite5,
        },
        {
          id: 2.3,
          title: `КОМФОРТНОСТ`,
          description: `Врвниот мотоцикл за турнеи треба да може да ги уништи големите патувања со удобност и стил. Roadmaster Elite е дизајниран со складирање што може да се заклучува и удобности на патниците.`,
          image: Roadmaster2025Elite6,
        },
      ],
    },
]

export { RoadmasterElite2025FeaturedItems };