import { Link } from "react-router-dom";
import Parallax from "../Main Components/Parallax";
import ButtonCTA from "./ButtonCTA";
import bloglist from "./constants/bloglist";
import motorcyclelist from "./constants/motorcycleslist";
import FTRMTFDetails from "../Sub Pages/Motorcycles Family/Single Motorcycle Family/FTR Family/FTRMTFDetails";
import { useEffect, useState } from "react";
import ScoutMTFDetails from "../Sub Pages/Motorcycles Family/Single Motorcycle Family/SCOUT Family/ScoutMTFDetails";
import Scout1250ccMTFDetails from "../Sub Pages/Motorcycles Family/Single Motorcycle Family/SCOUT 1250CC Family/Scout1250ccMTFDetails";
import CruiserMTFDetails from "../Sub Pages/Motorcycles Family/Single Motorcycle Family/CRUISER Family/CruiserMTFDetails";
import BaggerMTFDetails from "../Sub Pages/Motorcycles Family/Single Motorcycle Family/BAGGER Family/BaggerMTFDetails";
import TouringMTFDetails from "../Sub Pages/Motorcycles Family/Single Motorcycle Family/TOURING Family/TouringMTFDetails";
import DarkHorseMTFDetails from "../Sub Pages/Motorcycles Family/Single Motorcycle Family/DARK HORSE Family/DarkHorseMTFDetails";
import ELITEMTFDetails from "../Sub Pages/Motorcycles Family/Single Motorcycle Family/ELITE Family/ELITEMTFDetails";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Pagination, Scrollbar } from 'swiper/modules';

const HomeHero = () => {
  const [currentMotorcycleFamily, setCurrentMotorcycleFamily] =
    useState(FTRMTFDetails);

  useEffect(() => {
    setCurrentMotorcycleFamily(FTRMTFDetails);
    const elements = document.querySelectorAll(".motorcycle_category_title");
    elements.forEach((element) => {
      if (element.innerText === "FTR") {
        element.classList.add("motorcycle_category_title_active");
      }
    });
  }, []);

  const dataButtonCTA = [
    {
      id: 1,
      title: "Направете по желба",
      link: "https://www.indianmotorcycle.com/en-001/build-model/",
    },
    {
      id: 2,
      title: "Локации",
      link: "/find-a-dealer/map-view/",
    },
    {
      id: 3,
      title: "Тест возење",
      link: "/test-ride",
    },
  ];

  const handleMotorcycleFamily = async (e) => {
    const elements = document.querySelectorAll(
      ".motorcycle_category_title_active"
    );
    elements.forEach((element) => {
      element.classList.remove("motorcycle_category_title_active");
    });
    e.target.classList.add("motorcycle_category_title_active");
    switch (e.target.innerText) {
      case "FTR":
        await setCurrentMotorcycleFamily(FTRMTFDetails);
        break;
      case "Scout 1133cc":
        await setCurrentMotorcycleFamily(ScoutMTFDetails);
        break;
      case "Scout 1250cc":
        await setCurrentMotorcycleFamily(Scout1250ccMTFDetails);
        break;
      case "Cruiser":
        await setCurrentMotorcycleFamily(CruiserMTFDetails);
        break;
      case "Bagger":
        await setCurrentMotorcycleFamily(BaggerMTFDetails);
        break;
      case "Touring":
        await setCurrentMotorcycleFamily(TouringMTFDetails);
        break;
      case "Dark Hourse":
        await setCurrentMotorcycleFamily(DarkHorseMTFDetails);
        break;
      case "Elite":
        await setCurrentMotorcycleFamily(ELITEMTFDetails);
        break;
      default:
        await setCurrentMotorcycleFamily(FTRMTFDetails);
    }
  };

  return (
    <>
      <div className="container">
        <div className="home_buttoncta_container">
            {dataButtonCTA.map((item) => (
            <>
                <ButtonCTA title={item.title} link={item.link} />
            </>
            ))}
        </div>
      </div>
      <div className="home_motorcycle_category_mobile container mt-5 mb-5">
      <Swiper
        slidesPerView={2.5}
        scrollbar={{
          hide: true,
        }}
        navigation={true} 
        modules={[Navigation, Scrollbar]}
        className="mySwiper"
      >
  
        {motorcyclelist.map((motorcycle) => (
          <>
          <SwiperSlide>
            <p
              className="motorcycle_category_title"
              onClick={(e) => handleMotorcycleFamily(e)}
            >
              {motorcycle.title}
            </p>
            </SwiperSlide>
          </>
        ))}
             </Swiper>
      </div>

      <div className="home_motorcycle_category container mt-5 mb-5 home_motorcycle_category_desktop">
        {motorcyclelist.map((motorcycle) => (
          <>
            <p
              className="motorcycle_category_title"
              onClick={(e) => handleMotorcycleFamily(e)}
            >
              {motorcycle.title}
            </p>
          
          </>
        ))}
      </div>
 

      <div className="home_motorcycle_category mb-5">
        <Swiper
          spaceBetween={30}
          centerInsufficientSlides={true}
          modules={[Pagination]}
          className="mySwiper"
          breakpoints={{
            320: {
              slidesPerView: 2,
              spaceBetween: 20,
              width: 320,
            },
            480: {
              slidesPerView: 3,
              spaceBetween: 30,
              width: 480,
            },
            640: {
              slidesPerView: 4,
              spaceBetween: 40,
              width: 640,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 50,
              width: 768,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 60,
            },
          }}
        >
          {currentMotorcycleFamily.map((motorcycle) => (
            <>
              <SwiperSlide key={motorcycle.id}>
                <div className="motorcycle_category_inner" key={motorcycle.id}>
                  <Link to={motorcycle.link}>
                    <img src={motorcycle.image} alt="" />
                  </Link>
                  <h1 style={{ fontWeight: "600" }}>{motorcycle.title}</h1>
                  <a
                    className="btn text-white mt-3"
                    style={{ backgroundColor: "#862633" }}
                    href={motorcycle.link}
                  >
                    погледни
                  </a>
                </div>
              </SwiperSlide>
            </>
          ))}
        </Swiper>
      </div>

      {/* <div className="home_blog_container">
            {
                bloglist.map((item) => (
                    <>
                        <div className="blog_item">
                            <img alt="" src={item.image}></img>
                            <Link to={item.link} className="blog_item_title">{item.title}</Link>
                        </div>
                    </>
                ))
            }
            </div> */}

      {/* <Parallax/> */}
    </>
  );
};

export default HomeHero;
