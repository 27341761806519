import FTRRCarbon from './FTR Family Images/ftr-r-carbon-fiber.webp';
import FTRRally from './FTR Family Images/ftr-rally-black-smoke.webp';
import FTRSport from './FTR Family Images/ftr-sport-white-lightening-over-indy-red.webp';
import FTR from './FTR Family Images/ftr-onyx-black.webp';
import FTRHooligan from './FTR Family Images/ftr-hooligan.jpg';
import FTRXRCarbon from './FTR Family Images/ftr-x-100-r-carbon.jpg';
import FTRXRCarbonBlueCandyIcon from '../../../Single Motorcycle/FTR Motorcycles/FTR Motorcycles Images/FTRXRCarbonBlueCandyIcon.png';
import FTRBlackMetallic from '../../../Single Motorcycle/FTR Motorcycles/FTR Motorcycles Images/FTRBlackMetallic.webp';
import FTRBlackMetallicIcon from '../../../Single Motorcycle/FTR Motorcycles/FTR Motorcycles Images/FTRBlackMetallicIcon.png';
import FTRStealthGray from '../../../Single Motorcycle/FTR Motorcycles/FTR Motorcycles Images/FTRStealthGray.webp';
import FTRStealthGrayIcon from '../../../Single Motorcycle/FTR Motorcycles/FTR Motorcycles Images/FTRStealthGrayIcon.png';
import FTRIndyRedWhite from '../../../Single Motorcycle/FTR Motorcycles/FTR Motorcycles Images/FTRIndyRedWhite.jpg';
import FTRIndyRedWhiteIcon from '../../../Single Motorcycle/FTR Motorcycles/FTR Motorcycles Images/FTRIndyRedWhiteIcon.png';

import FTRRallyBlackSmoke from '../../../Single Motorcycle/FTR Motorcycles/FTR Motorcycles Images/FTRRallyBlackSmoke.webp';
import FTRRallyBlackSmokeIcon from '../../../Single Motorcycle/FTR Motorcycles/FTR Motorcycles Images/FTRRallyBlackSmokeIcon.webp';
import FTRSportBlackMetallic from '../../../Single Motorcycle/FTR Motorcycles/FTR Motorcycles Images/FTRSportBlackMetallic.webp';
import FTRSportBlackMetallicIcon from '../../../Single Motorcycle/FTR Motorcycles/FTR Motorcycles Images/FTRSportBlackMetallicIcon.webp';
import FTRSportGrayBlue from '../../../Single Motorcycle/FTR Motorcycles/FTR Motorcycles Images/FTRSportGrayBlue.jpg';
import FTRSportGrayBlueIcon from '../../../Single Motorcycle/FTR Motorcycles/FTR Motorcycles Images/FTRSportGrayBlueIcon.png';
import FTRSportGrayBlack from '../../../Single Motorcycle/FTR Motorcycles/FTR Motorcycles Images/FTRSportGrayBlack.jpg';
import FTRSportGrayBlackIcon from '../../../Single Motorcycle/FTR Motorcycles/FTR Motorcycles Images/FTRSportGrayBlackIcon.png';
import FTRSportIndyRed from '../../../Single Motorcycle/FTR Motorcycles/FTR Motorcycles Images/FTRSportIndyRed.webp';
import FTRSportIndyRedIcon from '../../../Single Motorcycle/FTR Motorcycles/FTR Motorcycles Images/FTRSportIndyRedIcon.webp';
import FTRRCarbonFiber from '../../../Single Motorcycle/FTR Motorcycles/FTR Motorcycles Images/ftr-r-carbon-fiber.webp';
import FTRRCarbonFiberIcon from '../../../Single Motorcycle/FTR Motorcycles/FTR Motorcycles Images/ftr-r-carbon.png';
import FTRRCarbonFiberRed from '../../../Single Motorcycle/FTR Motorcycles/FTR Motorcycles Images/FTRRCarbonFiberRed.jpg';
import FTRRCarbonFiberRedIcon from '../../../Single Motorcycle/FTR Motorcycles/FTR Motorcycles Images/FTRRCarbonFiberRedIcon.png';


const FTRMTFDetails = [
    {
        id: 1,
        image: FTRRCarbon,
        title: `FTR R Carbon`,
        price: `18 990 €`,
        link: `/ftr-r-carbon`,
        members: [
            {
                color: `Carbon Fiber`,
                price: `18 990 €`,
                icon: FTRRCarbonFiberIcon,
                image: FTRRCarbonFiber
            },
            {
                color: `Carbon Fiber`,
                price: `18 990 €`,
                icon: FTRRCarbonFiberRedIcon,
                image: FTRRCarbonFiberRed
            }
        ]
    },
    {
        id: 2,
        image: FTRRally,
        title: `FTR Rally`,
        price: `17 190 €`,
        link: `/ftr-rally`,
        members: [
            {
                color: `Black Smoke`,
                price: `17 190 €`,
                icon: FTRRallyBlackSmokeIcon,
                image: FTRRallyBlackSmoke
            }
        ]
    },
    {
        id: 3,
        image: FTRSport,
        title: `FTR Sport`,
        price: `17 190 €`,
        link: `/ftr-sport`,
        members: [
            {
                color: `Black Metallic`,
                price: `17 190 €`,
                icon: FTRSportBlackMetallicIcon,
                image: FTRSportBlackMetallic
            },
            {
                color: `Granite Gray Blue`,
                price: `17 490 €`,
                icon: FTRSportGrayBlueIcon,
                image: FTRSportGrayBlue
            },
            {
                color: `Storm Gray Black`,
                price: `17 490 €`,
                icon: FTRSportGrayBlackIcon,
                image: FTRSportGrayBlack
            },
            {
                color: `White Lightning / Indy Red`,
                price: `17 490 €`,
                icon: FTRSportIndyRedIcon,
                image: FTRSportIndyRed
            }
        ]
    },
    {
        id: 4,
        image: FTR,
        title: `FTR`,
        price: `14 490 €`,
        link: `/ftr`,
        members: [
            {
                color: `Black Metallic`,
                price: `14 490 €`,
                icon: FTRBlackMetallicIcon,
                image: FTRBlackMetallic
            },
            {
                color: `Stealth Gray / Orange Burst`,
                price: `14 490 €`,
                icon: FTRStealthGrayIcon,
                image: FTRStealthGray
            },
            {
                color: `Indy Red White`,
                price: `14 490 €`,
                icon: FTRIndyRedWhiteIcon,
                image: FTRIndyRedWhite
            }
        ]
    },
    {
        id: 5,
        image: FTRHooligan,
        title: `FTR X RSD Super Hooligan`,
        price: `20 490 €`,
        link: `/ftr-x-rsd-super-hooligan`,
        members: [
            {
                color: `Black Metallic`,
                price: `20 490 €`,
                icon: FTRIndyRedWhiteIcon,
                image: FTRHooligan
            }
        ]
    },
    {
        id: 6,
        image: FTRXRCarbon,
        title: `FTR X 100% R CARBON`,
        price: `20 990 €`,
        link:  `/ftr-x-100-r-carbon`,
        members: [
            {
                color: 'Blue Candy Carbon',
                price: `20 990 €`,
                icon: FTRXRCarbonBlueCandyIcon,
                image: FTRXRCarbon
            }
        ]
    }
]

export default FTRMTFDetails;