import Roadmaster2025Elite1 from '../Carousel Images/2025-roadmasterelite-1.jpg';
import Roadmaster2025Elite2 from '../Carousel Images/2025-roadmasterelite-2.jpg';
import Roadmaster2025Elite3 from '../Carousel Images/2025-roadmasterelite-3.jpg';
import Roadmaster2025Elite4 from '../Carousel Images/2025-roadmasterelite-4.jpg';
import Roadmaster2025Elite5 from '../Carousel Images/2025-roadmasterelite-5.jpg';
import Roadmaster2025Elite6 from '../Carousel Images/2025-roadmasterelite-6.jpg';


const RoadmasterElite2025CarouselImages = [
    {
        id: 1,
        image: Roadmaster2025Elite1
    },
    {
        id: 2,
        image: Roadmaster2025Elite2
    },
    {
        id: 3,
        image: Roadmaster2025Elite3
    }, 
    {
        id: 4,
        image: Roadmaster2025Elite4
    },
    {
        id: 5,
        image: Roadmaster2025Elite5
    },
    {
        id: 6,
        image: Roadmaster2025Elite6
    }
]

export { RoadmasterElite2025CarouselImages }